<template>
  <v-dialog v-model="dialog" width="800px" persistent> 
    <v-card>
      <v-card-title>
        จำกัดการเข้าหน้า
      </v-card-title>
      <v-divider/>
       <v-card-actions>
          <v-row>
            <v-col cols="3" xs="12">
              <v-select label="dashboard" :items="permissionItems" outlined dense />
            </v-col>
            <v-col cols="3" xs="12">
              <v-select label="สรุป-ฝากถอน" :items="permissionItems" outlined dense />
            </v-col>
            <v-col cols="3" xs="12">
              <v-select label="พนักงาน" :items="permissionItems" outlined dense />
            </v-col>
            <v-col cols="3" xs="12">
              <v-select label="สมาชิก" :items="permissionItems" outlined dense hide-details/>
               <permission-button/>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="3" xs="12">
              <v-select label="พันธมิตร" :items="permissionItems" outlined dense />
            </v-col>
            <v-col cols="3" xs="12">
              <v-select label="แนะนำเพื่อน" :items="permissionItems" outlined dense />
            </v-col>
            <v-col cols="3" xs="12">
              <v-select label="โบนัสแนะนำเพื่อน" :items="permissionItems" outlined dense />
            </v-col>
            <v-col cols="3" xs="12">
              <v-select label="โบนัสคืนยอดเสีย" :items="permissionItems" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="3" xs="12">
              <v-select label="สร้างคูปอง" :items="permissionItems" outlined dense hide-details/>
               <permission-button/>
            </v-col>
            <v-col cols="3" xs="12">
              <v-select label="ฝากเงิน" :items="permissionItems" outlined dense />
            </v-col>
            <v-col cols="3" xs="12">
              <v-select label="เครดิต(รอฝาก)" :items="permissionItems" outlined dense />
            </v-col>
            <v-col cols="3" xs="12">
              <v-select label="เครดิต" :items="permissionItems" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col cols="3" xs="12">
              <v-select label="ถอนเงิน" :items="permissionItems" outlined dense hide-details />
              <permission-button/>
            </v-col>
            <v-col cols="3" xs="12">
              <v-select label="AUTO&โยกเงิน" :items="permissionItems" outlined dense />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="success" @click="save"> save</v-btn>
          <v-btn color="error" @click="$emit('setDialog',false)"> close</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PermissionButton from '@/components/staff/PermissionButton.vue'
export default {
  components: {
    PermissionButton
  },
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      permissionItems: [
        {
          text: 'อนุญาติ',
          value: 'accept',
        },
        {
          text: 'ไม่อนุญาต',
          value: 'denied',
        },
      ],
    }
  },
  methods: {
    save() {
      
    }
  }
}
</script>