<template>
  <v-menu transition="slide-y-transition" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark x-small v-bind="attrs" v-on="on">
        <span class="white--text">สิทธิ์แก้ไข</span>
        <v-icon small color="white">mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-title>
          <span class="px-4">สถานะ</span>
          <v-switch v-model="switchValue" color="success" :label="switchValue ? 'อนุญาติ' : 'บล็อค'"></v-switch>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    status: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    switchValue: {
      get() {
        return this.status
      },set(value) {
      this.$emit('setValue',value)
    }
    }
  }
}
</script>